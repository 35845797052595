<template>
 <basic-layout header="News">
   <div class="bg-white bg-opacity-90">
     <container class="pt-20">
      <div class="flex items-start">
        <div class="flex flex-col items-end">
          <h1 class="sm:text-6xl text-4xl text-green-700 mx-2 font-bold">{{news.title}}</h1>
          <span class="text-gray-500 italic text-lg -mt-1">{{news.date}}</span>
        </div>
      </div>
       <div v-if="!news.multiple" class="py-16">
         <div class="sm:container sm:mx-auto p-10 lg:grid lg:grid-cols-2">
           <div class="lg:col-span-1 min-h-96  flex   sm:justify-center items-center">
             <div>
               <div class="relative   @apply transform hover:rotate-2 hover:shadow-xl  hover:scale-105 transition duration-500 ease-in-out py-3 sm:max-w-xl sm:mx-auto">
                 <div class="absolute sm:w-96 index-1 inset-0 bg-green-600 shadow-lg transform -rotate-6 rounded-3xl"></div>
                 <div class="relative sm:w-96 p-2 bg-white shadow-lg rounded-3xl p-6 ">
                   <div class="max-w-md flex justify-center text-center">
                     <img class="w-full block h-full" src="../../assets/images/news/topresearcher.jpg"/>
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div class="lg:col-span-1 min-h-80 sm:p-10 flex flex-col items-center justify-center text-center">
             <p class="font-sans text-green-700 font-bold text-2xl  sm:text-4xl mt-10 text-gray-700 sm:mt-0 ">
               {{news.info.name}}
             </p>
             <span class="text-lg text-gray-500">{{news.info.details}}</span>
             <span class="text-lg text-gray-500 italic">({{news.info.campus}})</span>
             <div class="mt-4 animate-bounce">
               <h1 class="text-green-700 font-semibold text-4xl">Congratulations!</h1>
             </div>
           </div>
         </div>
       </div>
     </container>
     <div id="top-5-researcher" class="bg-gray-600 bg-opacity-50">
       <container class="p-20 text-white">
         <div class="flex items-center flex-col justify-center ">
           <h1 class=" text-3xl sm:text-5xl font-bold">Top 5 Researchers</h1>
         </div>

         <div v-for="(top,i) in topFiveResearcher" :key="i" class="flex flex-col items-center justify-center py-10 text-xl">
           <span class="text-2xl font-semibold text-green-600">{{top.campus}}</span>
           <span class="text-xl font-semibold text-green-600">{{top.date}}</span>
           <div v-for="(details,i) in top.details" :key="i" class="mb-8">
             <p  class="mt-8">{{details.college}}</p>
            <div class="flex flex-col items-center justify-center">
              <ol class="mt-6">
                <li v-for="(candidate,i) in details.candidates" :key="i">{{i+1}}. {{candidate}}</li>
              </ol>
            </div>
           </div>
         </div>
         <h1 class="text-green-700 text-center font-semibold text-4xl">Congratulations!</h1>
       </container>
     </div>
   </div>
 </basic-layout>
</template>

<script>
import BasicLayout from "../layouts/BasicLayout";
import Container from "../layouts/Container";
export default {
  name: "NewsDetails",
  components: {Container, BasicLayout},
  data:()=>({
    news:{
      multiple: false,
      title: 'Top Researcher',
      date: 'January 2020',
      info:{
        name: 'Ms. Jackielyn S. Wagan',
        campus: 'Main Campus',
        details: 'BSABE Student; from Bayuin, Socorro Oriental Mindoro'
      }
    },
    topFiveResearcher: [
      {
        date: 'Month of January 2020',
        campus: 'Main Campus',
        details:[
          {
            college: 'College of Agriculture and Allied Fields',
            candidates:['Sael, Ronald M. (BSH-I)','Laguerta, Gio Earl (BSA-I)','Atienza, Jamielyne Rizza R. (BSABE-I)','Asi, Jenifer D. (BSA-I)','Machon, Rogeon S. (ABEng-I)']
          },
          {
            college: 'College of Computer Studies, College of Business Management & High School Department',
            candidates: ['Regala, Aira R (BSed)','Manalo, Mikaela (Grade 10)','Alviz, Kaye B. (Grade 10)','Agaloos, Nicole (Grade 10)','Alfonso, Jesielyn (Grade 10)']
          },
          {
            college: 'College of Teacher Education and College of Arts and Sciences',
            candidates: ['Wagan, Jackielyn S. (BSABE-II)','Tabilog, Assel Jaira (BSED-Eng I)','Bautista, Marnelli A. (BSA I)','Bonbon, Baby Diane M. (BEED-I)','Manalo, Arnold A. (BSED-Fil I)']
          }
        ]
      },
      {
        date: 'Month of January 2020',
        campus: 'Calapan City Campus',
        details:[
          {
            college: null,
            candidates:['Aguilar, Gem (BTVTED)','Abejuela, Joel (BSED)','Atienza, Rexander Jhim (BSIT)','Faner, Proffer Lhastly (BSIT)','Maranan, Laiza (BSED)']
          },
        ]
      },
      {
        date: 'Month of February 2020',
        campus: 'Calapan City Campus',
        details:[
          {
            college: null,
            candidates:['Hernandez, Rosemarie (BSED)','Maala, Angel Mary (BSED)', 'De Guzman, Ederlyn (BSED)','Gabor, Mikee (BSED)','Del Gado, Marvin (BSED)']
          },
        ]
      }
    ]
  })
}
</script>

<style scoped>

</style>